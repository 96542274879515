const getApiHost = () => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }

  return '/api';
};

const config = {
  apiHost: getApiHost()
};

export default config;
