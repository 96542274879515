import './pagination_select.scss';

import React from 'react';

import classnames from 'classnames';
import { AsyncPaginate } from 'react-select-async-paginate';

import Select from '../Select/Select';
import { defaultSelectStyles } from 'components/Select/defaultSelectStyles';
import { isFunction, noop, uniqBy } from 'lodash';
import PropTypes from 'prop-types';

const mainClassName = 'pagination-select';

const propTypes = {
  className: PropTypes.string,
  loadOptions: PropTypes.func.isRequired,
  additional: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool
  ]),
  isMulti: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  loadingMessage: PropTypes.string,
  error: PropTypes.string,
  styles: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  hideLabel: PropTypes.bool,
  label: PropTypes.any,
  fetchOptionsAfterErrorButtonLabel: PropTypes.string,
  isFetchingOptions: PropTypes.bool,
  errorAfterFetchingOptions: PropTypes.bool,
  fetchOptionsAfterError: PropTypes.func,
  fetchingOptionsMessage: PropTypes.string,
  errorMessageAfterFetchingOptions: PropTypes.string,
  noOptionsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
  maxOptionsVisible: PropTypes.number,
  menuPlacement: PropTypes.oneOf(['top', 'bottom', 'auto'])
};

const defaultProps = {
  className: '',
  additional: {},
  disabled: false,
  isMulti: false,
  clearable: true,
  isSearchable: true,
  hideLabel: false,
  loadingMessage: 'Trwa ładowanie...',
  error: '',
  styles: {},
  placeholder: 'Wybierz',
  onBlur: noop,
  label: '',
  fetchOptionsAfterErrorButtonLabel: 'Spróbuj ponownie',
  isFetchingOptions: false,
  errorAfterFetchingOptions: false,
  fetchOptionsAfterError: null,
  errorMessageAfterFetchingOptions: 'Wystąpił błąd z pobraniem opcji do wyboru',
  noOptionsMessage: 'Brak opcji',
  maxOptionsVisible: 6,
  menuPlacement: 'bottom'
};

const PaginationSelect = props => {
  const {
    loadOptions,
    className,
    value,
    hideLabel,
    disabled,
    isMulti,
    clearable,
    isSearchable,
    onChange,
    onBlur,
    placeholder,
    styles,
    error,
    label,
    maxOptionsVisible,
    menuPlacement,
    labelClassName,
    hideEmptyError,
    noOptionsMessage,
    additional,
    loadingMessage,
    options,
    reduceOptions,
    loadOptionsOnMenuOpen,
    ...restProps
  } = props;

  const classes = classnames(
    mainClassName,
    { [`${mainClassName}--without-label`]: hideLabel },
    className
  );

  const renderNoOptionsMessage = () => {
    if (noOptionsMessage && isFunction(noOptionsMessage)) {
      return noOptionsMessage(value);
    }

    return noOptionsMessage;
  };

  return (
    <div className={classes}>
      {label && !hideLabel && (
        <label className={classnames(labelClassName, `${mainClassName}__label`)}>{label}</label>
      )}
      <AsyncPaginate
        {...restProps}
        options={options}
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        additional={additional}
        isDisabled={disabled}
        isMulti={isMulti}
        isClearable={clearable}
        isSearchable={isSearchable}
        placeholder={placeholder}
        loadingMessage={() => loadingMessage}
        maxMenuHeight={maxOptionsVisible * Select.optionHeight + Select.containerPaddingTop}
        styles={{
          ...defaultSelectStyles,
          ...(styles || {})
        }}
        menuPlacement={menuPlacement}
        noOptionsMessage={renderNoOptionsMessage}
        menuPortalTarget={document.body}
        loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
        reduceOptions={(previousOptions, loadedOptions) =>
          uniqBy([...previousOptions, ...loadedOptions], item => item.value)
        }
      />
      {(!hideEmptyError || error) && (
        <div
          className={classnames(
            `${mainClassName}__error-field`,
            `${mainClassName}__error-field--filled`
          )}
        >
          {error || ''}
        </div>
      )}
    </div>
  );
};

PaginationSelect.propTypes = propTypes;
PaginationSelect.defaultProps = defaultProps;

export default PaginationSelect;
