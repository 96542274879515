import './login.scss';

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { trim } from 'lodash';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';

import Button from 'components/Button/Button';

import FormikFieldCheckbox from 'libs/formik/FormikFieldCheckbox';

import FormikFieldInput from 'libs/formik/FormikFieldInput';
import { useAuth } from 'modules/Session/AuthContext';
import { useAxios } from 'context/AxiosProvider';
import getLoginSchemaValidation from 'modules/Session/Login/components/LoginForm/getLoginSchemaValidation';
import loginPaths from 'modules/Session/loginPaths';

const initialValues = { email: '', password: '', organization: 1 };

const mainClassName = 'login-form';

const LoginForm = () => {
  const intl = useIntl();
  const axios = useAxios();
  const history = useHistory();
  const { onAuth } = useAuth();

  const loginMutation = useMutation(
    ({ email, password, organization, rememberToken }) => {
      return axios.post('/auth/token/login/', {
        email,
        password,
        organization: organization.value
      });
    },
    {
      onSuccess: ({ auth_token }, values) => {
        onAuth(auth_token, values.rememberToken);
      },
      onError: error => {
        console.error(error);
        toast.error('Niepoprawne dane logowania');
      }
    }
  );

  return (
    <div className={mainClassName}>
      <Formik
        initialValues={initialValues}
        validationSchema={getLoginSchemaValidation(intl)}
        onSubmit={loginMutation.mutateAsync}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form className="login__form">
            <FormikFieldInput
              name="email"
              type="email"
              label={intl.formatMessage(
                defineMessage({
                  id: 'session.login.email-placeholder',
                  defaultMessage: 'E-mail'
                })
              )}
              error={errors.email}
              touched={touched.email}
            />
            <FormikFieldInput
              name="password"
              type="password"
              label={intl.formatMessage(
                defineMessage({
                  id: 'session.login.password-placeholder',
                  defaultMessage: 'Hasło'
                })
              )}
              normalize={value => trim(value)}
              error={errors.password}
              touched={touched.password}
            />
            <div className="login__form-actions">
              <FormikFieldCheckbox
                name="rememberToken"
                label={
                  <FormattedMessage
                    id="session.login.remember-me"
                    defaultMessage="Zapamiętaj mnie"
                  />
                }
                error={errors.rememberToken}
                touched={touched.rememberToken}
              />
              <Button type="submit" loading={isSubmitting}>
                <FormattedMessage id="session.login.login-button" defaultMessage="Zaloguj się" />
              </Button>
              <div className="login__form-reset">
                <Link to="/reset-password">
                  <FormattedMessage
                    id="session.login.recover-password-button"
                    defaultMessage="Zapomniałeś hasła?"
                  />
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
