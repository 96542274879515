import './card.scss';
import React from 'react';

const mainClassName = 'card';

const Card = ({ children, padding }) => {
  return (
    <div className={mainClassName} style={{ padding }}>
      {children}
    </div>
  );
};

export default Card;
