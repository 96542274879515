export const defaultSelectStyles = {
  container: base => {
    return {
      ...base,
      position: 'relative',
      border: '1px solid #E7E7E7',
      backgroundColor: '#fff',
      borderRadius: '5px'
    };
  },
  control: base => {
    return {
      ...base,
      // border: '1px solid #AEAEAE',
      border: 'none',
      borderRadius: '5px'
    };
  },
  valueContainer: base => {
    return {
      ...base,
      padding: '11px 22px',
      margin: '0px',
      minHeight: '45px'
    };
  },
  input: base => {
    return {
      ...base,
      margin: '0px',
      padding: '0px'
    };
  },
  option: (base, { isDisabled, isFocused, isSelected }) => {
    return {
      ...base,
      textAlign: 'left',
      backgroundColor: isDisabled ? null : isSelected ? '#272727' : isFocused ? '#f8f9fa' : null,
      cursor: isDisabled ? 'not-allowed' : 'pointer',

      ':active': {
        ...base[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#272727' : '#f8f9fa')
      },
      fontSize: 16,
      color: isDisabled ? '#818181' : isSelected ? 'white' : '#444',
      fontWeight: 600
    };
  },
  placeholder: base => {
    return {
      ...base,
      fontSize: 16,
      fontWeight: 400,
      color: '#adb5bd'
    };
  },

  menu: (base, props) => {
    return {
      ...base,
      marginTop: '5px',
      marginBottom: '5px',
      cursor: 'pointer'
    };
  },
  singleValue: provided => {
    return {
      ...provided,
      color: '#272727',
      fontSize: 16
    };
  },
  multiValue: provided => {
    return {
      ...provided,
      backgroundColor: '#272727',
      color: 'white'
    };
  },
  menuPortal: base => ({
    ...base,
    zIndex: 9999
  }),
  multiValueLabel: base => {
    return {
      ...base,
      paddingTop: '2px',
      paddingBottom: '2px',
      color: 'white'
    };
  },
  multiValueRemove: base => ({
    ...base,
    backgroundColor: '#272727',
    borderLeft: `1px solid #7ea215`,
    ':hover': {
      ...base[':hover'],
      backgroundColor: '#272727',
      color: 'white',
      cursor: 'pointer'
    }
  }),
  dropdownIndicator: base => {
    return {
      ...base,
      padding: '8px',
      color: '#2c405a'
    };
  },
  indicatorSeparator: base => ({ ...base, display: 'none' })
};
