import './small_circle_loader.scss';
import React from 'react';

const SmallCircleLoader = ({ size = 20, color }) => {
  return (
    <div className="loader-wrapper">
      <div className="loader" style={{ fontSize: size, color: color }}></div>
    </div>
  );
};

export default SmallCircleLoader;
