import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useAxios } from 'context/AxiosProvider';
import Button from 'components/Button/Button';
import { useAuth } from 'modules/Session/AuthContext';
import FormikFieldOrganizationSelect from 'modules/Session/Login/components/OrganizationSelect/FormikFieldOrganizationSelect';

const mainMessageId = 'Session.Login.LoginOrganizationForm';
const mainClassName = 'login-organization-form';

const initialValues = { organization: null };
const LoginOrganizationForm = ({}) => {
  const intl = useIntl();
  const axios = useAxios();
  const history = useHistory();
  const { onAuthToOrganization, logout } = useAuth();

  useEffect(() => {
    return history.listen((location, action) => {
      action === 'POP' && logout();
    });
  }, [history]);

  const loginOrganizationMutation = useMutation(
    ({ organization }) => {
      console.log({ organization });
      return axios.post('/auth/token/login/', {
        organization: organization.value
      });
    },
    {
      onSuccess: ({ auth_token }, { organization: { value: organizationId } }) => {
        onAuthToOrganization(auth_token, organizationId);
      },
      onError: error => {
        console.error(error);
        toast.error(
          intl.formatMessage(
            defineMessage({
              id: `${mainMessageId}.login-to-organization-failure`,
              defaultMessage: 'Logowanie do organisazji się nie powiodło spróbuj ponownie'
            })
          )
        );
      }
    }
  );

  return (
    <div className={mainClassName}>
      <Formik initialValues={initialValues} onSubmit={loginOrganizationMutation.mutateAsync}>
        {({ isSubmitting, errors, touched }) => (
          <Form className="login__form">
            <FormikFieldOrganizationSelect
              name="organization"
              type="organization"
              label={intl.formatMessage(
                defineMessage({
                  id: `${mainMessageId}.select-organization-label`,
                  defaultMessage: 'Wybierz organizację'
                })
              )}
              error={errors.organization}
              touched={touched.organization}
            />
            <Button onClick={logout}>
              <FormattedMessage id={`${mainMessageId}.back-to-login`} defaultMessage="Anuluj" />
            </Button>
            <Button type="submit" loading={isSubmitting}>
              <FormattedMessage id={`${mainMessageId}.submit`} defaultMessage="Wybierz" />
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginOrganizationForm;
