import qs from 'qs';
import { isNil, pickBy } from 'lodash';

const queryParamsStringify = queryParams =>
  qs.stringify(
    pickBy(queryParams, value => !isNil(value) && value !== ''),
    { arrayFormat: 'repeat', skipNulls: true, addQueryPrefix: false }
  );

export default queryParamsStringify;
