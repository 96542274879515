import { useField } from 'formik';
import React from 'react';
import OrganizationSelect from 'modules/Session/Login/components/OrganizationSelect/OrganizationSelect';

const FormikFieldOrganizationSelect = ({ label, ...restProps }) => {
  const [field, meta, helpers] = useField(restProps);

  return (
    <OrganizationSelect
      {...restProps}
      {...meta}
      {...field}
      label={label}
      onChange={option => {
        helpers.setValue(option);
      }}
      error={meta.touched && meta.error ? meta.error : ''}
    />
  );
};

export default FormikFieldOrganizationSelect;
