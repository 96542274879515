import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import ReactModal from 'react-modal';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import { mainPaths } from 'const/paths';
import Session from 'modules/Session/Session';
import AppContext from './components/AppContext';
import AxiosProvider from 'context/AxiosProvider';
import { AuthProvider } from 'modules/Session/AuthContext';

import { commonRoutes, onlyPublicRoutes, privateRouteByUserRole } from './routes';
import QueryClientProvider from 'context/QueryClientProvider';

ReactModal.setAppElement('body');

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [asideToggle, setAsideToggle] = useState(false);
  const toggleAside = () => {
    setAsideToggle(!asideToggle);
  };
  const userSettings = {
    asideToggle: asideToggle,
    toggleAside
  };

  return (
    <AppContext.Provider value={userSettings}>
      <AuthProvider>
        <AxiosProvider>
          <QueryClientProvider>
            <ToastContainer />
            <div className="app" id="main-container">
              <Session>
                {({ isAuthorized, userRole, location }) => {
                  if (!isAuthorized) {
                    return (
                      <Switch>
                        {[...commonRoutes, ...onlyPublicRoutes]?.map((props, index) => (
                          <Route key={index} {...props} />
                        ))}
                        <Redirect
                          to={{
                            pathname: mainPaths.LOGIN,
                            state: {
                              destinationLocation: location
                            }
                          }}
                        />
                      </Switch>
                    );
                  } else {
                    const route = privateRouteByUserRole[userRole];

                    if (!route) {
                      return <div>Rola użytkownika nie jest obsługiwana w aplikacji.</div>;
                    }

                    if (
                      location?.state?.destinationLocation?.pathname?.indexOf?.(route.path) === 0
                    ) {
                      return <Redirect to={location.state.destinationLocation} />;
                    }

                    return (
                      <Switch>
                        {[...commonRoutes]?.map((props, index) => (
                          <Route key={index} {...props} />
                        ))}
                        <Route path={route.path} component={route.component} />

                        <Redirect to={route.path} />
                      </Switch>
                    );
                  }
                }}
              </Session>
            </div>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AxiosProvider>
      </AuthProvider>
    </AppContext.Provider>
  );
};

export default App;
