import './input.scss';

import React from 'react';
import classnames from 'classnames';

import { ReactComponent as IcoEyeIcon } from 'icons/eye.svg';
import { ReactComponent as IcoEyeSlashIcon } from 'icons/eye-slash.svg';

const mainClassName = 'input';

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  state = {
    showPass: false
  };

  togglePasswordVisible = () => this.setState(state => ({ showPass: !state.showPass }));

  renderInput = () => {
    const {
      type,
      rows,
      name,
      id,
      onChange,
      value,
      onFocus,
      onBlur,
      placeholder,
      disabled,
      autoComplete,
      autoFocus,
      variant
    } = this.props;

    const commonProps = {
      className: classnames(`${mainClassName}__input`, {
        [`${mainClassName}__input--disabled`]: disabled,
        [`${mainClassName}--search`]: variant === 'search'
      }),
      ref: this.inputRef,
      id,
      name,
      value,
      onChange,
      onFocus,
      onBlur,
      placeholder,
      disabled,
      autoComplete,
      autoFocus
    };

    switch (variant) {
      case 'textarea':
        return <textarea rows={rows ? rows : 5} {...commonProps} />;

      case 'search':
        return <input type="search" {...commonProps} />;

      default:
        return <input type={!this.state.showPass ? type : 'text'} {...commonProps} />;
    }
  };

  renderIcon() {
    const { type, value } = this.props;

    const commonProps = {
      height: 18,
      width: 18,
      className: `${mainClassName}__input-icon`
    };
    const { showPass } = this.state;

    if (!value) {
      return null;
    }

    if (type === 'password') {
      const PasswordIcon = showPass ? IcoEyeSlashIcon : IcoEyeIcon;

      return <PasswordIcon {...commonProps} onClick={this.togglePasswordVisible} />;
    }

    return null;
  }

  render() {
    const { label, type, name, value, error, disabled } = this.props;
    const classes = classnames(mainClassName, {
      [`${mainClassName}--is-filled`]: !!value?.length,
      [`${mainClassName}--pass`]: type === 'password',
      [`${mainClassName}--disabled`]: disabled
    });

    return (
      <div className={classes}>
        {label && (
          <label className={`${mainClassName}__label`} htmlFor={name}>
            {label}
          </label>
        )}
        <div style={{ position: 'relative' }}>
          {this.renderInput()}
          {this.renderIcon()}
        </div>
        {error && <div className={`${mainClassName}__error`}>{error}</div>}
      </div>
    );
  }
}

export default Input;
