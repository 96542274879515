import './login.scss';

import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import BasicLayout from 'components/BasicLayout/BasicLayout';
import Card from 'components/Card/Card';
import LoginForm from 'modules/Session/Login/components/LoginForm/LoginForm';
import { useAuth } from 'modules/Session/AuthContext';
import loginPaths from 'modules/Session/loginPaths';
import LoginOrganizationForm from 'modules/Session/Login/components/LoginOrganizationForm/LoginOrganizationForm';

const mainClassName = 'login';
const Login = () => {
  const { mainToken } = useAuth();
  const match = useRouteMatch();

  return (
    <BasicLayout>
      <div className={mainClassName}>
        <Card padding={65}>
          <Switch>
            {!mainToken ? (
              <Route path={`${match.path}${loginPaths.LOGIN}`} component={LoginForm} />
            ) : (
              <Route
                path={`${match.path}${loginPaths.LOGIN_SELECT_ORGANIZATION}`}
                component={LoginOrganizationForm}
              />
            )}
            <Redirect
              to={`${match.path}${
                mainToken ? loginPaths.LOGIN_SELECT_ORGANIZATION : loginPaths.LOGIN
              }`}
            />
          </Switch>
        </Card>
      </div>
    </BasicLayout>
  );
};

export default Login;
