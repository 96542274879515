import './select.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import ReactSelect from 'react-select';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Loader from 'components/Loader/Loader';

import { defaultSelectStyles } from './defaultSelectStyles';

import { isFunction, noop } from 'lodash';

export default class Select extends Component {
  static optionHeight = 44;
  static containerPaddingTop = 4;

  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
      PropTypes.bool
    ]).isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object,
          PropTypes.bool,
          PropTypes.array
        ])
      })
    ).isRequired,
    isMulti: PropTypes.bool,
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    isSearchable: PropTypes.bool,
    loadingMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    error: PropTypes.string,
    styles: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    hideLabel: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    fetchOptionsAfterErrorButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isFetchingOptions: PropTypes.bool,
    errorAfterFetchingOptions: PropTypes.bool,
    fetchOptionsAfterError: PropTypes.func,
    fetchingOptionsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorMessageAfterFetchingOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    noOptionsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    maxOptionsVisible: PropTypes.number,
    menuPlacement: PropTypes.oneOf(['top', 'bottom', 'auto'])
  };

  static defaultProps = {
    className: '',
    disabled: false,
    isMulti: false,
    clearable: true,
    isSearchable: true,
    hideLabel: false,
    loadingMessage: 'Trwa ładowanie...',
    error: '',
    styles: {},
    placeholder: 'Wybierz',
    onBlur: noop,
    label: '',
    fetchOptionsAfterErrorButtonLabel: 'Spróbuj ponownie',
    isFetchingOptions: false,
    errorAfterFetchingOptions: false,
    fetchOptionsAfterError: null,
    fetchingOptionsMessage: 'Trwa ładowanie opcji do wyboru',
    errorMessageAfterFetchingOptions: 'Wystąpił błąd z pobraniem opcji do wyboru',
    noOptionsMessage: 'Brak opcji',
    maxOptionsVisible: 6,
    menuPlacement: 'bottom'
  };

  renderPlaceholder = () => {
    const { isLoading, loadingMessage, placeholder } = this.props;

    if (isLoading) return loadingMessage;

    return placeholder;
  };

  renderNoOptionsMessage = value => {
    const {
      isFetchingOptions,
      errorAfterFetchingOptions,
      fetchOptionsAfterError,
      fetchingOptionsMessage,
      errorMessageAfterFetchingOptions,
      noOptionsMessage,
      fetchOptionsAfterErrorButtonLabel
    } = this.props;

    if (errorAfterFetchingOptions) {
      return (
        <ErrorMessage
          message={errorMessageAfterFetchingOptions}
          action={
            fetchOptionsAfterError && fetchOptionsAfterErrorButtonLabel
              ? {
                  onClick: fetchOptionsAfterError,
                  label: fetchOptionsAfterErrorButtonLabel
                }
              : null
          }
        />
      );
    }

    if (isFetchingOptions) {
      return <Loader message={fetchingOptionsMessage} />;
    }

    if (isFunction(noOptionsMessage)) {
      return noOptionsMessage(value);
    }

    return noOptionsMessage;
  };

  render() {
    const {
      className,
      value,
      options,
      hideLabel,
      disabled,
      isMulti,
      clearable,
      isSearchable,
      onChange,
      onBlur,
      placeholder,
      styles,
      error,
      label,
      maxOptionsVisible,
      menuPlacement,
      labelClassName,
      hideEmptyError,
      ...restProps
    } = this.props;

    const mainClassName = 'react-select';

    const classes = classnames(
      mainClassName,
      { [`${mainClassName}--without-label`]: hideLabel },
      className
    );

    return (
      <div className={classes}>
        {label && !hideLabel && (
          <label className={classnames(labelClassName, `${mainClassName}__label`)}>{label}</label>
        )}
        <ReactSelect
          {...restProps}
          className={classes}
          value={value}
          options={options}
          isDisabled={disabled}
          isMulti={isMulti}
          isClearable={clearable}
          isSearchable={isSearchable}
          onChange={onChange}
          placeholder={this.renderPlaceholder()}
          maxMenuHeight={maxOptionsVisible * Select.optionHeight + Select.containerPaddingTop}
          styles={{
            ...defaultSelectStyles,
            ...(styles || {})
          }}
          menuPlacement={menuPlacement}
          noOptionsMessage={this.renderNoOptionsMessage}
          menuPortalTarget={document.body}
        />
        {(!hideEmptyError || error) && (
          <div
            className={classnames(
              `${mainClassName}__error-field`,
              `${mainClassName}__error-field--filled`
            )}
          >
            {error || ''}
          </div>
        )}
      </div>
    );
  }
}
