import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useAxios } from 'context/AxiosProvider';

const OwnQueryClientProvider = ({ children }) => {
  const axios = useAxios();

  const defaultQueryFn = async ({ queryKey }) => {
    return await axios.get(queryKey[0]);
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
        refetchOnWindowFocus: false
      }
    }
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default OwnQueryClientProvider;
