import React, { useContext, useState, createContext } from 'react';

import { useLocalStorage } from 'use-hooks';
import { uniqBy, find } from 'lodash';

const AuthContext = createContext();

export const AuthProvider = props => {
  const [mainTokenInLS, setMainTokenLS] = useLocalStorage('mainToken', '');
  const [mainToken, setMainToken] = useState(mainTokenInLS || '');

  const [organizationTokensLS, setOrganizationTokensIdLS] = useLocalStorage(
    'organizationTokens',
    []
  );
  const [organizationTokens, setOrganizationTokens] = useState(organizationTokensLS || []);
  const [organizationId, setOrganizationId] = useState(organizationTokensLS?.[0]?.id);

  const onAuth = (mainToken, saveInLocalStorage = false) => {
    setMainToken(mainToken);

    if (saveInLocalStorage) {
      setMainTokenLS(mainToken);
    }
  };

  const onAuthToOrganization = (
    organizationToken,
    selectedOrganisationId,
    { saveInLocalStorage = !!mainTokenInLS } = {}
  ) => {
    setOrganizationId(selectedOrganisationId);
    const newOrganizationTokens = uniqBy(
      [{ id: selectedOrganisationId, token: organizationToken }, ...organizationTokens],
      ({ id }) => id
    );

    if (saveInLocalStorage) {
      setOrganizationTokensIdLS(newOrganizationTokens);
    }

    setOrganizationTokens(newOrganizationTokens);
  };

  const logout = () => {
    setMainTokenLS('');
    setMainToken('');
    setOrganizationTokens('');
    setOrganizationTokensIdLS([]);
  };

  return (
    <AuthContext.Provider
      value={{
        mainToken,
        organizationToken: find(organizationTokens, { id: organizationId })?.token,
        onAuth,
        logout,
        onAuthToOrganization
      }}
      {...props}
    />
  );
};

export const useAuth = () => {
  const { mainToken, organizationToken, onAuth, logout, onAuthToOrganization } = useContext(
    AuthContext
  );

  return { mainToken, organizationToken, onAuth, logout, onAuthToOrganization };
};
