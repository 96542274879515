import './error_message.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'components/Button/Button';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  className: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  action: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onClick: PropTypes.func
  }),
  center: PropTypes.bool,
  messageAlign: PropTypes.string,
  inline: PropTypes.bool
};

const defaultProps = {
  className: '',
  message: '',
  action: null,
  center: false,
  messageAlign: 'center',
  inline: false
};

const ErrorMessage = ({ className, message, action, center, messageAlign, inline }) => {
  const classes = classnames(
    'error-message',
    { 'text-center': center, 'error-message--inline': inline },
    className
  );

  return (
    <div className={classes}>
      <div
        className={classnames('error-message__message', {
          'error-message__message--inline': inline
        })}
        style={{ textAlign: messageAlign }}
      >
        {message}
      </div>
      {action && (
        <div
          className={classnames('error-message__actions', {
            'error-message__actions--inline': inline
          })}
        >
          <Button
            size="smaller"
            kind={action.kind || 'outline'}
            onClick={action.onClick}
            disabled={action.disabled}
          >
            {action.label || (
              <FormattedMessage
                id="global.error_messages.try_again"
                defaultMessage="Spróbuj ponownie"
              />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

ErrorMessage.propTypes = propTypes;
ErrorMessage.defaultProps = defaultProps;

export default ErrorMessage;
