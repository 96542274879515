import React, { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useDebounce } from 'use-hooks';

import queryParamsStringify from 'utils/queryParamsStringify';
import { useAxios } from 'context/AxiosProvider';
import infiniteQueryGetNextPageParam from 'utils/infiniteQueryGetNextPageParam';
import PaginationSelect from 'components/PaginationSelect/PaginationSelect';

const prepareOrganizationOption = organizations =>
  organizations?.map(organization => ({
    label: organization.name,
    value: organization.id
  })) || [];

const PAGE_SIZE = 100;
const OrganizationSelect = ({ onChange, value, label, ...restProps }) => {
  const axios = useAxios();
  const [search, setSearch] = useState('');
  const searchDebounce = useDebounce(search, 300);

  const { fetchNextPage } = useInfiniteQuery(
    `/organizations/dashboard/?${queryParamsStringify({
      search: searchDebounce,
      page_size: PAGE_SIZE
    })}`,
    ({ pageParam = 1, queryKey }) =>
      axios(`${queryKey}&page=${pageParam}`).then(response => {
        const firsOrganization = response.results?.[0];
        pageParam === 1 &&
          firsOrganization &&
          !value &&
          onChange({ label: firsOrganization.name, value: firsOrganization.id });
        return response;
      }),
    {
      getNextPageParam: infiniteQueryGetNextPageParam
    }
  );

  return (
    <PaginationSelect
      {...restProps}
      label={label}
      value={value}
      errorAfterFetchingOptions={true}
      onInputChange={setSearch}
      isLoading={true}
      loadOptions={(search, loadedOptions, { page = 1 }) => {
        return fetchNextPage().then(({ data }) => {
          const pageData = data.pages[page - 1];

          return {
            options: prepareOrganizationOption(pageData.results),
            hasMore: !!pageData.next,
            additional: {
              page: page + 1
            }
          };
        });
      }}
      onChange={onChange}
    />
  );
};

export default OrganizationSelect;
