import { createContext, useContext, useMemo } from 'react';
import Axios from 'axios';
import config from 'config/config';
import { useAuth } from 'modules/Session/AuthContext';

export const AxiosContext = createContext(undefined);

export const AxiosProvider = ({ children }) => {
  const { organizationToken, mainToken } = useAuth();
  const token = organizationToken || mainToken;

  const axios = useMemo(() => {
    const axios = Axios.create({
      headers: {
        'Content-Type': 'application/json'
      },
      baseURL: config.apiHost
    });

    axios.interceptors.request.use(config => {
      if (token) {
        config.headers.Authorization = `Token ${token}`;
      }

      return config;
    });

    axios.interceptors.response.use(response => {
      return response.data;
    });

    return axios;
  }, [token]);

  return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
};

export const useAxios = () => useContext(AxiosContext);

export default AxiosProvider;
