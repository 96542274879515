import { useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Session = ({ children }) => {
  const { organizationToken } = useAuth();

  const location = useLocation();
  const isAuthorized = !!organizationToken;
  const userRole = 'user';

  return children({ userRole, isAuthorized, location });
};

export default Session;
