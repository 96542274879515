import { useState, useEffect, useCallback } from 'react';

const verifyModifierKeys = (modifierKeys, event) =>
  !Object.keys(modifierKeys).filter(modifierKey => modifierKeys[modifierKey] && !event[modifierKey])
    .length;

const useCustomKeyPress = (
  targetKey,
  modifierKeys = { ctrlKey: false, shiftKey: false, altKey: false, metaKey: false },
  afterPress
) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    event => {
      if (event.key === targetKey && verifyModifierKeys(modifierKeys, event)) {
        setKeyPressed(true);
        afterPress(event);
      }
    },
    [targetKey, afterPress, modifierKeys]
  );

  // If released key is our target key then set to false
  const upHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    },
    [targetKey]
  );

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
};

export default useCustomKeyPress;
