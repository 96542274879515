import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  center: PropTypes.bool
};

const defaultProps = {
  className: '',
  message: '',
  center: false
};

export const Loader = ({ message, className, center }) => {
  const classes = classnames('loader', { 'text-center': center }, className);

  return <div className={classes}>{`${message}...`}</div>;
};

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;
