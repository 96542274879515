import * as Yup from 'yup';
import { defineMessage } from 'react-intl';

const getLoginSchemaValidation = intl =>
  Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage(
          defineMessage({
            id: 'session.login.email-is-incorrect',
            defaultMessage: 'E-mail jest niepoprawny'
          })
        )
      )
      .required(
        intl.formatMessage(
          defineMessage({
            id: 'session.login.email-is-required',
            defaultMessage: 'E-mail jest wymagany'
          })
        )
      ),
    password: Yup.string().required(
      intl.formatMessage(
        defineMessage({
          id: 'session.login.password-is-required',
          defaultMessage: 'Hasło jest wymagane'
        })
      )
    )
  });

export default getLoginSchemaValidation;
