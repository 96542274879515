import './reset-pass.scss';

import React from 'react';
import { Formik, Form } from 'formik';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';

import BasicLayout from 'components/BasicLayout/BasicLayout';
import Button from 'components/Button/Button';

import FormikFieldInput from 'libs/formik/FormikFieldInput';
import getLoginSchemaValidation from 'modules/Session/Login/components/LoginForm/getLoginSchemaValidation';

const initialValues = { email: '' };

const ResetPass = () => {
  const intl = useIntl();

  return (
    <BasicLayout>
      <div className="reset-pass">
        <Formik
          initialValues={initialValues}
          validationSchema={getLoginSchemaValidation(intl)}
          onSubmit={e => alert('Reset pass')}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className="reset-pass__form">
              <FormikFieldInput
                name="email"
                type="email"
                label={intl.formatMessage(
                  defineMessage({
                    id: 'session.reset-pass.email-placeholder',
                    defaultMessage: 'E-mail'
                  })
                )}
                error={errors.email}
                touched={touched.email}
              />
              <div className="reset-pass__form-actions">
                <Button type="submit" loading={isSubmitting}>
                  <FormattedMessage id="session.reset-pass.button" defaultMessage="Resetuj hasło" />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </BasicLayout>
  );
};

export default ResetPass;
