import './button.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmallCircleLoader from 'components/SmallCircleLoader/SmallCircleLoader';
import { ReactComponent as AddButton } from 'icons/add-button.svg';

const mainClassName = 'button';

const propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  disabled: PropTypes.bool,
  fill: PropTypes.bool,
  loading: PropTypes.bool
};

const defaultProps = {
  className: '',
  type: 'button',
  disabled: false,
  fill: false,
  loading: false,
  kind: 'button'
};

const Button = ({ className, type, disabled, fill, onClick, children, loading, kind }) => {
  const classes = classnames(
    mainClassName,
    {
      [`${mainClassName}--disabled`]: disabled,
      [`${mainClassName}--loading`]: loading,
      [`${mainClassName}--kind-${kind}`]: kind,
      fill: !!fill
    },
    className
  );
  return (
    <button
      className={classes}
      onClick={disabled || loading ? () => {} : onClick}
      disabled={disabled || loading}
      type={type}
    >
      {loading && (
        <div className={`${mainClassName}__loader-wrapper`}>
          <SmallCircleLoader size={12} color={'white'} />
        </div>
      )}
      {kind === 'add' && <AddButton />}
      {children}
    </button>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
