import { mainPaths } from 'const/paths';
import userRole from 'const/userRole';

import PrivacyPolicy from 'containers/public/PrivacyPolicy';
import ResetPass from 'modules/Session/ResetPass/ResetPass';
import Crm from 'modules/Crm';
import Login from 'modules/Session/Login/Login';

export const commonRoutes = [
  {
    path: mainPaths.PRIVACY_POLICY,
    component: PrivacyPolicy
  }
];

export const onlyPublicRoutes = [
  {
    path: mainPaths.LOGIN,
    component: Login
  },
  {
    path: mainPaths.RESET_PASSWORD,
    component: ResetPass
  }
];

export const privateRouteByUserRole = {
  [userRole.USER]: {
    path: mainPaths.CRM_PANEL,
    component: Crm
  },
  [userRole.ADMIN]: {
    path: mainPaths.ADMIN_PANEL,
    component: e => <p>admin</p>
  }
};
