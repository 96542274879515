import React, { useCallback, useState } from 'react';

import { IntlProvider as ReactIntlProvider } from 'react-intl';
import useCustomKeyPress from 'hooks/useCustomKeyPress';
import enMessages from 'translations/en.json';
import plMessages from 'translations/pl.json';
import devMessages from 'translations/dev.json';

const messages = {
  en: enMessages,
  pl: plMessages,
  dev: devMessages
};

const IntlContext = React.createContext();

const modifierKeys = { ctrlKey: true, altKey: true };

if (process.env.NODE_ENV !== 'production') {
  const originalConsoleError = console.error;
  if (console.error === originalConsoleError) {
    console.error = (...args) => {
      if (args?.[0]?.code?.indexOf?.('MISSING_TRANSLATION') === 0) {
        return;
      }
      originalConsoleError.call(console, ...args);
    };
  }
}

export const OwnIntlProvider = ({ children }) => {
  const navigationLanguage = navigator.language.split(/[-_]/)[0];
  const [locale, setLocale] = useState(navigationLanguage in messages ? navigationLanguage : 'pl');
  const [lastLocale, setLastLocale] = useState(locale);

  const changeLocale = useCallback(
    newLocale => {
      if (!(newLocale in messages)) {
        throw new Error(`Wrong locale pass to selectLanguage: ${newLocale}`);
      } else {
        setLastLocale(locale);
        setLocale(newLocale);
      }
    },
    [setLastLocale, setLocale, locale]
  );

  const toggleDevMessages = useCallback(() => {
    changeLocale(locale === 'dev' ? lastLocale : 'dev');
  }, [changeLocale, locale, lastLocale]);

  useCustomKeyPress('d', modifierKeys, toggleDevMessages);

  return (
    <IntlContext.Provider value={{ locale, changeLocale }}>
      <ReactIntlProvider
        messages={messages[locale]}
        locale={locale === 'dev' ? 'pl' : locale}
        key={locale}
      >
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  );
};
