export const mainPaths = {
  LOGIN: '/login',
  LOGIN_SELECT_ORGANISATION: '/login/organization',
  LOGOUT: '/logout',
  RESET_PASSWORD: '/reset-password',
  PRIVACY_POLICY: '/privacy-policy',

  ADMIN_PANEL: '/admin',
  CRM_PANEL: '/crm'
};
