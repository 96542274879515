import './checkbox.scss';

import React from 'react';
import classnames from 'classnames';

const mainClassName = 'checkbox';
const Checkbox = ({ label, value, name, error, ...restProps }) => {
  return (
    <div className={mainClassName}>
      <label className={`${mainClassName}__label`}>
        <input type="checkbox" name={name} value={value} {...restProps} />
        <span>{label}</span>
      </label>
      {error && (
        <div
          className={classnames(
            `${mainClassName}__error-field`,
            `${mainClassName}__error-field--filled`
          )}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
